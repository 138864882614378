/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ComponentProps } from "react";
import { Input } from "@hyperlocal/vital";
import ReactInputMask, { Props } from "react-input-mask";
import * as S from "./InputMask.style";

export interface InputMaskProps extends Props {
  label?: string;
  type?: ComponentProps<typeof Input>["type"];
  helper?: "error" | "info" | "success";
  helperText?: string;
}

export function InputMask({
  label,
  maskPlaceholder = null,
  type = "text",
  helper,
  helperText,
  ...props
}: InputMaskProps) {
  return (
    <>
      {/* @ts-ignore */}
      <ReactInputMask
        data-testid="input-mask"
        maskPlaceholder={maskPlaceholder}
        {...props}
      >
        {/* @ts-ignore */}
        <S.Input
          type={type}
          label={label}
          helper={helper}
          helperText={helperText}
        />
      </ReactInputMask>
    </>
  );
}
