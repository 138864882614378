import { useEffect } from "react";
import { Hooks } from "@/utils/utility";

interface UseDrawerScrollLockProps {
  isOpen: boolean;
}

export const useDrawerScrollLock = ({ isOpen }: UseDrawerScrollLockProps) => {
  const { isIos } = Hooks.useMobileDetect();
  const isMobile = Hooks.useMediaQuery("mobile");

  useEffect(() => {
    const rootId = "#single-spa-application\\:\\@hyperlocal\\/banking-charge";
    const element = document.querySelector(rootId);

    if (!element) return;

    const rootElement = element as HTMLElement;

    if (isOpen && isIos && isMobile) {
      const scrollPosition = window.scrollY;

      rootElement.style.setProperty("position", "fixed");
      rootElement.style.setProperty("width", "100%");
      rootElement.style.setProperty("height", "100%");
      rootElement.style.setProperty("overflow", "hidden");
      rootElement.style.setProperty("top", `-${scrollPosition}px`);

      return () => {
        rootElement.style.removeProperty("position");
        rootElement.style.removeProperty("width");
        rootElement.style.removeProperty("height");
        rootElement.style.removeProperty("overflow");
        rootElement.style.removeProperty("top");

        window.scrollTo(0, scrollPosition);
      };
    }
  }, [isIos, isMobile, isOpen]);
};
