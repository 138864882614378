import { useCheckAppVersion } from "@/api/use-check-app-version";
import { ChargeRoutes } from "./Charge.routes";
import { FutureInstallmentsRoutes } from "./FutureInstallments.routes";

export function Routes() {
  useCheckAppVersion();

  return (
    <>
      <ChargeRoutes />
      <FutureInstallmentsRoutes />
    </>
  );
}
